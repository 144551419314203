<template>
  <main class="main_v8">
    <span>SPAN tag text example</span><br/>
    <a href="#" @click.prevent>A tag text example</a><br/>
    <p>P tag text example</p>

    <h1>H1 tag text example</h1>
    <h2>H2 tag text example</h2>
    <h3>H3 tag text example</h3>
  </main>
  
</template>

<script>
export default {
  // created() {
  //   document.querySelector('html').style.fontSize = '21px';
  // }
}
</script>

<style lang="scss" scoped>
  // .main_v8{
  //   padding-top: 100px;
  // }
</style>


